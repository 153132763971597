<template>
    <div>
        <video ref="videoPlayer" :class="classes"></video>
    </div>
</template>

<script>
import videojs from 'video.js';
import '@videojs/http-streaming/dist/videojs-http-streaming.min';
import 'videojs-contrib-quality-levels/dist/videojs-contrib-quality-levels.min';
import 'videojs-hls-quality-selector/dist/videojs-hls-quality-selector.min'
import 'videojs-hotkeys/videojs.hotkeys.min'

export default {
    name: "index",
    props: {
        classes: {type: String, default: "vjs-default-skin vjs-big-play-centered video-js vjs-fluid my_original_video_modal-dimensions"},
        src: {
            type: String, default: undefined
        },
        options: {
            type: Object, default() {
                return {
                    autoplay: true,
                    controls: true,
                    preload: 'auto',
                    playbackRates: [0.5, 1, 1.5, 2],
                    sources: [],
                    'data-setup': {"fluid": true},
                }
            }
        },
        hotkeys: {
            type: Object, default() {
                return {
                    volumeStep: 0.1,
                    seekStep: 5,
                    enableModifiersForNumbers: false
                }
            }
        },
        hlsQualitySelector: {type: Boolean, default: false}
    },
    data() {
        return {
            player: null
        }
    },
    created() {
        if(this.src) {
            this.options.sources.push({
                src: this.src
            });
        }
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, () => {
            //this.player.log('onPlayerReady', this.player.hotkeys);
            if (Object.keys(this.hotkeys).length) {
                this.player.hotkeys(this.hotkeys)
            }
        });


        if (this.hlsQualitySelector) {
            this.player.hlsQualitySelector();
        }
    },
    unmounted() {
        if (this.player) {
            this.player.dispose();
        }
    }
}
</script>

<style lang="scss">
@import "~video.js/dist/video-js.min.css";
@import "~@silvermine/videojs-quality-selector/dist/css/quality-selector.css";

</style>